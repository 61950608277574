import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GsAlarmStatusComponent } from './gs-alarm-status.component';
import { CenteredMessageModule } from '../centered-message/centered-message.module';

import { MatTabsModule } from '@angular/material/tabs';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TacticalDashboardOverviewGaugeModule } from '../tactical-dashboard-overview-gauge/tactical-dashboard-overview-gauge.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { SparklineModule } from '@progress/kendo-angular-charts';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { MaterialModule } from 'projects/desktop/src/app/_other-modules/material.module';
import { TacticalNavigationModule } from '../tactical-navigation/tactical-navigation.module';
import { ChartTypeSliderModule } from '../chart-type-slider/chart-type-slider.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { NgxResize } from 'ngxtension/resize';

@NgModule({
  declarations: [
    GsAlarmStatusComponent
  ],
  imports: [
    CommonModule,
    CenteredMessageModule,
	NgxResize,
    MatTabsModule,
    LoadingSpinnerModule,
    MatTooltipModule,
    TacticalDashboardOverviewGaugeModule,
	ChartsModule,
	SparklineModule,
	ProgressBarModule,
	MaterialModule,
	TacticalNavigationModule,
	ChartTypeSliderModule,
	TourMatMenuModule,
  ],
  exports: [GsAlarmStatusComponent]
})
export class GsAlarmStatusModule { }
